import { amgApi } from '@/service/axios'

class EmployeesScheduleService {
  async createEmployeeSchedule(params) {
    try {
      const data = await amgApi.post(`attendance/create-employee-schedule`, params)
      return data
    } catch (error) {
      console.error('Something went wrong on createEmployeeSchedule:', error)
      throw error
    }
  }

  async getEmployeeScheduleList(params) {
    try {
      const data = await amgApi.post(`attendance/get-employee-schedule-list`, params);
      return data;
    } catch (err) {
      console.error('Something went wrong on getEmployeeScheduleList:', err)
    }
  }

  async editEmployeeSchedule(params) {
    try {
      const data = await amgApi.post(`attendance/edit-employee-schedule`, params);
      return data;
    } catch (err) {
      console.error('Something went wrong on getEmployeeScheduleList:', err)
    }
  }

  async getEmployeeScheduleTracking(params) {
    try {
      const data = await amgApi.post(`attendance/get-employee-schedule-tracking`, params);
      return data;
    } catch (error) {
      console.error('Something went wrong on getEmployeeScheduleTracking');
    }
  }

}

export default new EmployeesScheduleService();
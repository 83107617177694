export default [
    {
        key: 'employee_full_name',
        label: 'Employee',
        class: 'text-center',
    },
    {
        key: 'know_as',
        label: 'Known as',
        class: 'text-center',
    },
    {
        key: 'created_by',
        label: 'Created by',
        class: 'text-center',
    },
]
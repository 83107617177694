export default [
  {
    key: 'selected',
    label: '',
    visible: true,
    tdClass: 'text-center m-0 p-0',
  },
  {
    key: 'id',
    sortable: false,
    label: 'DOC',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },
  {
    key: 'first_name',
    sortable: false,
    label: 'First Name',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },
  {
    key: 'last_name',
    sortable: false,
    label: 'Last Name',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },
  {
    key: 'know_as',
    sortable: false,
    label: 'Know as',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },
  {
    key: 'mobile',
    sortable: false,
    label: 'Mobile',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },
  {
    key: 'department',
    sortable: false,
    label: 'Module',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },

  {
    key: 'rol',
    sortable: false,
    label: 'Rol',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',

  },
  {
    key: 'actions',
    label: 'Actions',
    thClass: 'text-center',
    tdClass: 'text-center m-0 p-0',
  },
];

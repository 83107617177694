export default [
    {
        key: "know_as",
        label: "Known as",
    },
    {
        key: "department",
        label: "Department",
    },
    {
        key: "actions",
        label: "Actions",
    },
]
export default [
    {
        key: 'day_of_the_week_text',
        label: 'Employee',
        class: 'text-center',
    },
    {
        key: 'work_start_time',
        label: 'Work Start Time',
        class: 'text-center',
    },
    {
        key: 'work_end_time',
        label: 'Work End Time',
        class: 'text-center',
    },
    {
        key: 'created_by',
        label: 'Created By',
        class: 'text-center',
    },
    {
        key: 'created_at',
        label: 'Created At',
        class: 'text-center',
    },
]
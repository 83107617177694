<template>
  <b-modal
    v-model="modalUp"
    ok-title="Send Request"
    size="md"
    hide-footer
    scrollable
    @hidden="hiddenModal()"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <h5 class="m-0 p-0 text-white">
          EMPLOYEE SCHEDULE TRACKING for "{{ dataItem.first_name.toUpperCase() }} {{ dataItem.last_name.toUpperCase() }}"
        </h5>
      </div>
    </template>
    <b-row>
      <b-col>
        <b-table
          ref="tracking-table"
          small
          :items="myProvider"
          :fields="fields"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobalDay }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Fields from '@/views/assistance/views/employees-schedule/data/employee-schedule-tracking.fields';
import EmployeesScheduleService from '@/views/assistance/views/employees-schedule/services/employee-schedule.services';

export default {
  props: {
    employeeId: {
      type: String,
      required: true,
    },
    dataItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    fields: Fields,
    isLoading: false,
    modalUp: true,
  }),
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const { data } = await EmployeesScheduleService.getEmployeeScheduleTracking({
          employee_id: this.employeeId,
        });
        this.removePreloader();
        return data;
      } catch (err) {
        console.error(err);
        this.removePreloader();
      }
    },
    hiddenModal() {
      this.$emit('close');
    },
    capitalizeNames(name) {
      return name
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
  },
  watch: {},
};
</script>

    <style scoped>
</style>

<template>
  <b-modal
    v-model="modalUp"
    centered
    ok-title="Send Request"
    size="xsm"
    hide-footer
    title-class="text-center"
    @hidden="hiddenModal()"
  >
    <template #modal-title>
      <!-- <pre>{{ dataItems }}</pre> -->
      <span class="text-white">{{ modalTitle.toUpperCase() }} for "{{ dataItems.first_name.toUpperCase() }} {{ dataItems.last_name.toUpperCase() }}"</span>
    </template>
    <b-row>
      <b-col
        v-for="(daySchedule, index) in employee_schedule"
        :key="index"
        cols="12"
      >
        <b-row>
          <b-col
            cols="2"
          ><div style="padding-top: 6px">
            {{ daySchedule.day_of_the_week_text }}
          </div></b-col>
          <b-col>
            <b-form-group>
              <kendo-timepicker
                v-model="daySchedule.work_start_time"
                :format="'HH:mm'"
                :interval="60"
                :disabled="daySchedule.validated == 1"
                class="w-100 rounded bg-transparent"
                placeholder="Hour"
                style="height: 2.73rem"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <kendo-timepicker
                v-model="daySchedule.work_end_time"
                :format="'HH:mm'"
                :interval="60"
                :disabled="daySchedule.validated == 1"
                class="w-100 rounded bg-transparent"
                placeholder="Hour"
                style="height: 2.73rem"
                :style="arrayErrorsSchedule[index] == daySchedule.work_end_time && (daySchedule.work_end_time != null && daySchedule.work_start_time != null)?'border:1px solid red;':''"
              />
              <div class="d-flex justify-content-start w-auto">
                <small
                  v-if="arrayErrorsSchedule[index] == daySchedule.work_end_time && (daySchedule.work_end_time != null && daySchedule.work_start_time != null)"
                  class="text-danger w-auto"
                >Greater than Start Hour</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col style="padding-top: 6px">
            <b-form-checkbox
              v-model="daySchedule.validated"
              value="1"
              unchecked-value="0"
              @change="setFreeDay(daySchedule.validated, index)"
            >Nonworking</b-form-checkbox>
          </b-col>
          <div>
            <tabler-icon
              v-b-tooltip.hover.top="'Apply this schedule for every day'"
              :icon="scheduleIsAppliedForEveryDay ? 'ChecksIcon' : 'CopyIcon'"
              class="mr-1 cursor-pointer"
              :class="
                scheduleIsAppliedForEveryDay ? 'text-success ' : 'text-primary'
              "
              style="margin-top: 8px"
              size="18"
              @click="
                applyScheduleForEveryDay(
                  daySchedule.work_start_time,
                  daySchedule.work_end_time
                )
              "
            />
          </div>
        </b-row>
      </b-col>
      <b-row class="mx-auto">
        <div class="mt-2">
          <b-button
            variant="primary"
            @click="setEmployeeSchedule"
          >{{
            actionButtonTitle
          }}</b-button>
        </div>
      </b-row>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Fields from '@/views/assistance/views/qr-generator/data/qr-code-modal-tracking.fields.data';
import DefaultSchedules from '@/views/assistance/views/employees-schedule/data/default.schedules';
import EmployeesSchedule from '@/views/assistance/views/employees-schedule/services/employee-schedule.services';

export default {
  props: {
    employeesList: {
      type: Array,
      required: true,
    },

    dataItems: {
      type: Object,
      default: () => ({}),
    },
    createMode: {
      type: Boolean,
      default: true,
    },
    multipleSetSchedule: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    scheduleIsAppliedForEveryDay: false,
    fields: Fields,
    isLoading: false,
    modalUp: true,
    employee_schedule: DefaultSchedules,
    employee_schedule_backup: DefaultSchedules,
    arrayErrorsSchedule: [],
  }),
  async created() {
    if (!this.multipleSetSchedule && !this.createMode) {
      await this.getExistingEmployeeSchedule();
    }
  },
  methods: {
    ...mapActions({
      A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        'NotificationStore/A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE',
    }),
    hiddenModal(status = false) {
      this.scheduleIsAppliedForEveryDay = false;
      this.$emit('close', status);
    },
    setFreeDay(valid, index) {
      if (valid == 1) {
        this.employee_schedule[index].work_end_time = null;
        this.employee_schedule[index].work_start_time = null;
      }
    },
    async setEmployeeSchedule() {
      if (!this.isValidSchedule()) {
        return;
      }
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;

      const params = {
        employees_list: this.employeesList,
        schedules: this.employee_schedule,
        user_id: this.currentUser.user_id,
      };

      try {
        const data = await EmployeesSchedule.createEmployeeSchedule(params);
        if (data.status === 200) {
          this.hiddenModal(true);
          this.showSuccessSwal();

          // update state employee without schedule
          await this.A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE({
            user_id: this.currentUser.user_id,
          });
        }
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    isValidSchedule() {
      let status = true;
      this.arrayErrorsSchedule = [];
      this.employee_schedule.forEach(element => {
        if (element.work_end_time <= element.work_start_time) {
          if (element.work_end_time != null && element.work_start_time != null) {
            status = status === true ? false : status;
            this.arrayErrorsSchedule.push(element.work_end_time);
          }
        }
      });
      return status;
    },
    applyScheduleForEveryDay(work_start_time, work_end_time) {
      this.scheduleIsAppliedForEveryDay = !this.scheduleIsAppliedForEveryDay;

      if (this.scheduleIsAppliedForEveryDay) {
        this.employee_schedule = this.employee_schedule.map(daySchedule => ({
          ...daySchedule,
          work_start_time,
          work_end_time,
        }));
      } else {
        this.employee_schedule = this.employee_schedule_backup;
      }
    },

    async getExistingEmployeeSchedule() {
      try {
        const { data: employee_schedule } = await EmployeesSchedule.getEmployeeScheduleList({
          employee_id: this.employeesList[0].employee_id,
        });
        this.employee_schedule = employee_schedule;
        this.employee_schedule_backup = employee_schedule;
      } catch (err) {
        console.error('Error in getExistingEmployeeSchedule', err);
      }
    },
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE:
        'NotificationStore/G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE',
    }),
    currentUserId() {
      return this.currentUser.user_id;
    },
    modalTitle() {
      if (this.multipleSetSchedule) return 'SET EMPLOYEES\' SCHEDULE';
      return this.createMode
        ? 'CREATE EMPLOYEE SCHEDULE'
        : 'EDIT EMPLOYEE SCHEDULE';
    },
    actionButtonTitle() {
      if (this.multipleSetSchedule) return 'SET';
      return this.createMode ? 'CREATE' : 'EDIT';
    },
  },
};
</script>

<style scoped>
</style>

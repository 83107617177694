<template>
  <b-modal
    centered
    v-model="modalState"
    scrollable
    title-class="h3 text-white font-weight-bolder text-center"
    size="lg"
    title="Employees to set schedule"
    @hidden="close()"
  >
    <b-row>
      <b-col>
        <b-table
          small
          show-empty
          :fields="fields"
          :items="locaEmployeesList"
          class="font-small-3 text-center"
          sticky-header="50vh"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(know_as)="data">
            {{ capitalizeNames(data.item.know_as) }}
          </template>
          <template #cell(actions)="data">
            <tabler-icon
              class="text-danger cursor-pointer"
              icon="TrashIcon"
              size="20"
              @click="removeEmployee(data.item.pid)"
            />
          </template>
          <template v-slot:cell(department)="data">
            <div
              style="font-weight: bold"
              v-for="(item, index) in JSON.parse(data.item.department)"
              :key="index"
            >
              {{ item }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        @click="setEmployeesSchedule"
        :disabled="!thereAreEmployeesSelected"
      >
        NEXT
      </b-button>
    </template>
  </b-modal>
</template>
    
  <script>
import { mapGetters } from "vuex";
import Fields from "@/views/assistance/views/employees-schedule/data/multiple-employees-schedule.fields.js";

export default {
  props: {
    employeesList: {
      type: Array,
    },
  },
  components: {},
  data() {
    return {
      modalState: true,
      locaEmployeesList: this.employeesList,
      fields: Fields,
      isBusy: false,
      selectAgent: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    thereAreEmployeesSelected() {
      return this.locaEmployeesList.length > 0;
    },
  },
  methods: {
    close(success = false) {
      this.$emit("close", success);
    },
    removeEmployee(pid) {
      this.locaEmployeesList = this.locaEmployeesList.filter(
        (employee) => employee.pid !== pid
      );
    },
    capitalizeNames(name) {
      if (!name) return "";
      return name
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    async setEmployeesSchedule() {
      this.$emit("updateEmployeesList", this.locaEmployeesList);
    },
  },
};
</script>
  <style>
.padding-y {
  padding: 5px 0 !important;
}
</style>
<template>
  <div>
    <filter-slot
      :filter="isRRHH || isManagement ? filters : []"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
    >
      <template #buttons>
        <button
          :disabled="!thereAreEmployeesSelected"
          style="background-color: #01E397; color: white; padding: 1rem; border:none; border-radius: 10px; margin-left: 1rem; font-weight:bold;"
          @click="openMultipleEmployeeScheduleAssignment"
        >
          Set Schedule
        </button>
      </template>
      <template #table>
        <b-table
          ref="employeesSchedule"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="myProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          striped
          hover
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(selected)>
            <b-form-checkbox
              v-model="allEmployeesAreSelected"
              style="padding: 0; margin:0;"
              @input="selectAllEmployees"
            />
          </template>
          <template
            #cell(selected)="data"
          >
            <div class="d-flex justify-content-center">
              <span style="padding-top: 1rem;">
                <b-form-group>
                  <b-form-checkbox
                    v-model="employeesSelectedForSchedule"
                    style="padding-right:17px"
                    :value="data.item"
                    :name="data.item.id"
                  />
                </b-form-group>
              </span>
            </div>
          </template>
          <template v-slot:cell(id)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              style="padding: 1rem 0;"
            >
              {{ data.item.pid }}
            </span></div>
          </template>
          <template v-slot:cell(first_name)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              style="padding: 1rem 0;"
            >
              {{ data.item.first_name }}
            </span></div>
          </template>
          <template v-slot:cell(last_name)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              style="padding: 1rem 0;"
            >
              {{ data.item.last_name }}
            </span></div>
          </template>
          <template v-slot:cell(status)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              :style="
                data.item.status === 1
                  ? 'color:#00CC00;font-weight:bold'
                  : 'color:#FF0000;font-weight:bold'
              "
            >
              {{ data.item.status === 1 ? "Active" : "Inactive" }}
            </span></div>
          </template>
          <template v-slot:cell(know_as)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              style="padding: 1rem 0;"
            >
              {{ data.item.know_as }}
            </span></div>
          </template>
          <template v-slot:cell(dob)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              style="padding: 1rem 0;"
            >
              {{ data.item.dob | myGlobal }}
            </span></div>
          </template>
          <template v-slot:cell(department)="data">
            <div
              v-for="(item, index) in JSON.parse(data.item.department)"
              :key="index"
              style="font-weight: bold; padding:1rem 0;"
            >
              {{ item }}
            </div>
          </template>
          <template v-slot:cell(mobile)="data">
            <div
              class="d-flex justify-content-center"
            >            <span
              style="padding: 1rem 0;"
            >
              <feather-icon
                v-if="data.item.mobile"
                icon="PhoneIcon"
                size="13"
              />
              {{ data.item.mobile ? data.item.mobile : "---" }}
            </span></div>

          </template>
          <template v-slot:cell(rol)="data">
            <div
              class="d-flex justify-content-center"
            >
              <span
                style="padding: 1rem 0;"
              >
                {{ data.item.rol }}
              </span>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <div
              class="d-flex justify-content-center"
              style="gap: 1rem; padding: .5rem 0"
            >
              <div
                style="padding: .5rem; border-radius: 5px"
                :style="data.item.has_schedule == 1 ? 'background-color: #01E397;' : 'background-color: #FC434B;'"
              >
                <feather-icon
                  v-b-tooltip.hover.top="
                    data.item.has_schedule == 1
                      ? 'Edit Schedule'
                      : 'Create Schedule'
                  "
                  class="cursor-pointer"
                  style="color: white;"
                  icon="ClockIcon"
                  size="18"
                  @click="
                    openCreateEmployeesSchedule(
                      data.item.has_schedule,
                      data.item.id,
                      data.item,
                    )
                  "
                />
              </div>
              <div
                class="bg-primary"
                style="padding: .5rem; border-radius: 5px"
              >
                <feather-icon
                  icon="ListIcon"
                  size="18"
                  style="color: white;"
                  @click="openEmployeeScheduleTracking(data.item.id, data.item)"
                />
              </div>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <create-employees-schedule
      v-if="createEmployeesScheduleOpened"
      :employees-list="employeesSelectedForSchedule"
      :data-items="dataItems"
      :create-mode="createMode"
      :multiple-set-schedule="multipleSetSchedule"
      @close="closeCreateEmployeesSchedule"
    />
    <employee-schedule-tracking
      v-if="employeeScheduleTrackingOpened"
      :employee-id="employeeId"
      :data-item="dataItem"
      @close="closeEmployeeScheduleTracking"
    />
    <multiple-employee-schedule-assignment
      v-if="multipleEmployeeScheduleAssignmentOpened"
      :employees-list="employeesSelectedForSchedule"
      @updateEmployeesList="updateEmployeesSelectedForSchedule"
      @close="closeMultipleEmployeeScheduleAssignment"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import fieldsData from '@/views/assistance/views/employees-schedule/data/schedules.fields';
import filtersData from '@/views/assistance/views/employees-schedule/data/schedules.filters';
import CreateEmployeesSchedule from '@/views/assistance/views/employees-schedule/modals/CreateEmployeeSchedule.vue';
import EmployeeScheduleTracking from '@/views/assistance/views/employees-schedule/modals/EmployeeScheduleTracking.vue';
import EmployeesService from '@/views/management/views/employees/employees.service';
import MultipleEmployeeScheduleAssignment from '@/views/assistance/views/employees-schedule/modals/MultipleEmployeeScheduleAssignment.vue';
import HelpDeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';

export default {
  components: {
    CreateEmployeesSchedule,
    EmployeeScheduleTracking,
    MultipleEmployeeScheduleAssignment,
  },
  data() {
    return {
      striped: true,
      allEmployeesAreSelected: false,
      basicSearch: true,
      createEmployeesScheduleOpened: false,
      employeeScheduleTrackingOpened: false,
      employeesSelectedForSchedule: [],
      dataItem: null,
      dataItems: null,
      createMode: null,
      employeeId: null,
      employees: [],
      fields: fieldsData,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      filters: filtersData,
      isBusy: false,
      items: [],
      multipleEmployeeScheduleAssignmentOpened: false,
      multipleSetSchedule: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      totalRows: 0,
    };
  },
  async created() {
    this.addPreloader();
    if (this.isManagement || this.isRRHH) {
      await this.getModules();
    }
    this.removePreloader();
  },
  methods: {
    async myProvider() {
      this.multipleSetSchedule = false;

      const params = {
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        text: this.filterPrincipal.model,
        departament:
          this.isManagement || this.isRRHH
            ? this.filters[0].model
            : this.currentModule,
      };
      try {
        const data = await EmployeesService.getEmployeesScheduleStatus(params);

        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalData = data.data.total;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
        this.employees = data.data.data;
        return this.employees || [];
      } catch (err) {
        console.error('Failed to get tolerance time rules', err);
      }
      return [];
    },
    refreshTable() {
      this.employeesSelectedForSchedule = [];
      this.allEmployeesAreSelected = false;

      this.$refs.employeesSchedule.refresh();
    },
    openCreateEmployeesSchedule(has_schedule, employee_id, dataItem) {
      this.dataItems = dataItem;
      this.createMode = has_schedule !== 1;
      this.employeesSelectedForSchedule = [{ employee_id }];
      this.createEmployeesScheduleOpened = true;
    },
    closeCreateEmployeesSchedule(status) {
      this.employeeId = null;
      this.createMode = null;
      this.createEmployeesScheduleOpened = false;
      this.multipleSetSchedule = false;
      this.employeesSelectedForSchedule = [];
      if (status) {
        this.refreshTable();
      }
    },
    openEmployeeScheduleTracking(employee_id, dataItems) {
      this.dataItem = dataItems;
      this.employeeId = employee_id;
      this.employeeScheduleTrackingOpened = true;
    },
    closeEmployeeScheduleTracking() {
      this.employeeId = null;
      this.employeeScheduleTrackingOpened = false;
    },
    selectAllEmployees() {
      this.employeesSelectedForSchedule = this.allEmployeesAreSelected
        ? this.employees.slice()
        : [];
    },
    openMultipleEmployeeScheduleAssignment() {
      this.multipleEmployeeScheduleAssignmentOpened = true;
    },
    closeMultipleEmployeeScheduleAssignment() {
      this.multipleEmployeeScheduleAssignmentOpened = false;
    },
    updateEmployeesSelectedForSchedule(employeesList) {
      this.employeesSelectedForSchedule = employeesList;
      this.nextToScheduleAssignment();
    },
    nextToScheduleAssignment() {
      this.multipleEmployeeScheduleAssignmentOpened = false;
      this.createEmployeesScheduleOpened = true;
      this.multipleSetSchedule = true;
      this.employeesSelectedForSchedule = this.employeesSelectedForSchedule.map(
        employee => ({ ...employee, employee_id: employee.id }),
      );
    },
    async getModules() {
      try {
        const { data } = await HelpDeskService.getModules();
        this.filters[0].options = data;
        this.filters[0].options.unshift({ id: null, name: '--All employees--' });
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentModule() {
      return this.currentUser.modul_id;
    },
    isManagement() {
      return this.currentModule === 16;
    },
    isRRHH() {
      return this.currentModule === 17;
    },
    thereAreEmployeesSelected() {
      return this.employeesSelectedForSchedule.length > 0;
    },
  },
};
</script>

<style scoped>
</style>

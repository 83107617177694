export default [
  {
    day_of_the_week_text: 'Monday',
    work_start_time: '09:00:00',
    work_end_time: '18:00:00',
    day_of_the_week: 2
  },
  {
    day_of_the_week_text: 'Tuesday',
    work_start_time: '09:00:00',
    work_end_time: '18:00:00',
    day_of_the_week: 3
  },
  {
    day_of_the_week_text: 'Wednesday',
    work_start_time: '09:00:00',
    work_end_time: '18:00:00',
    day_of_the_week: 4
  },
  {
    day_of_the_week_text: 'Thursday',
    work_start_time: '09:00:00',
    work_end_time: '18:00:00',
    day_of_the_week: 5
  },
  {
    day_of_the_week_text: 'Friday',
    work_start_time: '09:00:00',
    work_end_time: '18:00:00',
    day_of_the_week: 6
  },
  {
    day_of_the_week_text: 'Saturday',
    work_start_time: '09:00:00',
    work_end_time: '15:00:00',
    day_of_the_week: 7
  },
  {
    day_of_the_week_text: 'Sunday',
    work_start_time: '09:00:00',
    work_end_time: '15:00:00',
    day_of_the_week: 1
  },
]